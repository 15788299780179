import { shallowEqual, type TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import type Context from '@alltrails/core/types/Context';
import context from '@alltrails/context/slices/contextSlice';
import currencyCodeReducer from '@alltrails/context/slices/currencyCodeSlice';
import { featuresReducer as features, type Feature } from '@alltrails/features';
import plan from '@alltrails/context/slices/planSlice';
import plans from '@alltrails/context/slices/plansSlice';
import amplitudeAnalyticsSettings from '@alltrails/amplitude/slices/amplitudeAnalyticsSettings';
import { experimentsReducer as experiments } from '@alltrails/experiments';
import type Experiments from '@alltrails/core/types/Experiments';
import { MapState, mapReducer } from '@alltrails/maps';
import { configureStore, createSlice } from '@reduxjs/toolkit';
import type Plan from '@alltrails/core/types/Plan';
import CurrencyCode from '@alltrails/shared/types/CurrencyCode';
import lists, { type ListsState } from '@alltrails/redux/slices/lists';
import userConnections from '@alltrails/redux/slices/userConnections';
import userConnectionLists from '@alltrails/redux/slices/userConnectionLists';
import algoliaConfigs from '@alltrails/redux/slices/algoliaConfigs';
import { AlgoliaConfigs } from '@alltrails/shared/types/algoliaConfigs';
import { baseApi } from '@alltrails/redux-helpers';
import installBanner, { InstallBannerState } from '@alltrails/install-prompt/slices/installBanner';
import type CountryCode from '@alltrails/core/types/CountryCode';
import type RequiredPreloadedState from '@alltrails/core/types/RequiredPreloadedState';
import { listenerMiddleware } from './listenerMiddleware';

export type PreloadedState = RequiredPreloadedState &
  Partial<{
    algoliaConfigs: AlgoliaConfigs;
    installBanner: InstallBannerState;
    lists: ListsState;
    map: MapState;
  }>;

export const reducer = {
  [baseApi.reducerPath]: baseApi.reducer,
  algoliaConfigs,
  amplitudeAnalyticsSettings,
  browser: (state = {}) => state,
  context,
  currencyCode: currencyCodeReducer,
  experiments,
  features,
  installBanner,
  irclickid: (state = '') => state,
  lists,
  map: mapReducer,
  plan,
  plans,
  requestCountryCode: (state = '' as CountryCode) => state,
  userConnectionLists,
  userConnections
};

export const buildStore = (preloadedState?: PreloadedState) =>
  configureStore({
    reducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ immutableCheck: false }).prepend(listenerMiddleware.middleware).concat(baseApi.middleware)
  });

export type AppStore = ReturnType<typeof buildStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<AppStore['getState']> & { context?: Context };

// these are intended to use within the app only
export const useDispatch = (): AppDispatch => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = (selector: (state: RootState) => any, equalityFn = shallowEqual) =>
  useAppSelector<RootState, any>(selector, equalityFn);
