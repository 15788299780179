import { createSlice } from '@reduxjs/toolkit';
import type AmplitudeAnalyticsSettings from '@alltrails/core/types/AmplitudeAnalyticsSettings';

const amplitudeAnalyticsSettings = createSlice({
  name: 'amplitudeAnalyticsSettings',
  initialState: {} as AmplitudeAnalyticsSettings,
  reducers: {}
});

export default amplitudeAnalyticsSettings.reducer;
